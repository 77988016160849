@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@font-face {
    font-family: 'Shree Devanagari 714'; //This is what we are going to call
    src: url('../src/assets/fonts/ShreeDevanagari714.ttf');
    font-weight: normal;
    font-style: normal;
}

// * {
//     font-family: 'Shree Devanagari 714' !important;
// }

*:not(i) {
    font-family: 'Shree Devanagari 714' !important;
}

.form-control:disabled,
.form-control[readonly] {
    cursor: default;
}

.disabledForInscription {
    pointer-events: none;
    opacity: 0.3;
}

.fc .fc-toolbar-title {
    font-weight: 800;
    text-transform: capitalize;
}

/* .btn {
    background-color: red !important;
    // background: red !important;
} */

.loader-inmoapp{
    width: 7rem;
    height: 7rem;
    background-image: url('assets/images/logo/loader.gif');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}